<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/vehicle' }"
        >停车场列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>支付方式</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        >新增</el-button
      >
      <span class="name">{{ pkName }}</span>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-if="isShowData"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="type"
            label="支付方式名称"
          >
            <template slot-scope="scope">
              <span>{{ findQuery(scope.row.type, paytype) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.createTime">{{
                conversionTime(scope.row.createTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="modifyTime"
            label="更新时间"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.modifyTime">{{
                conversionTime(scope.row.modifyTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      :title="addpkName"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="40%"
      v-el-drag-dialog
    >
      <el-form label-width="150px">
        <el-form-item label="支付方式名称" prop="type">
          <el-select
            v-model="addForm.type"
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="judgmentPayment"
          >
            <el-option
              v-for="item in paytype"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 建行支付方式 -->
      <el-form
        :model="ccbAddForm"
        :rules="formRules"
        ref="ccbaddRef"
        label-width="150px"
        class="demo-ruleForm"
        v-show="ccb"
      >
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="ccbAddForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="聚合支付h5" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行商户号" prop="mchId">
          <el-input
            v-model="ccbAddForm.mchId"
            placeholder="请再次输入银行商户号"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行柜台代码" prop="posId">
          <el-input
            v-model="ccbAddForm.posId"
            placeholder="请输入银行柜台代码"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行分行代码" prop="bankId">
          <el-input
            v-model="ccbAddForm.bankId"
            placeholder="请输入银行分行代码"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公钥" prop="publicKey">
          <el-input
            v-model="ccbAddForm.publicKey"
            placeholder="请输入公钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行api密码" prop="quPwd">
          <el-input
            v-model="ccbAddForm.quPwd"
            placeholder="请输入银行api密码"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 微信支付方式 -->
      <el-form
        :model="weChatAddForm"
        :rules="formRules"
        ref="weChataddRef"
        label-width="150px"
        class="demo-ruleForm"
        v-show="weChat"
      >
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="weChatAddForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="原生h5" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APPID" prop="appId">
          <el-input
            v-model="weChatAddForm.appId"
            placeholder="请输入APPID"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信商户号" prop="mchId">
          <el-input
            v-model="weChatAddForm.mchId"
            placeholder="请再次输入微信商户号"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公众号的Appsecret" prop="quPwd">
          <el-input
            v-model="weChatAddForm.quPwd"
            placeholder="请输入公众号的Appsecret"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="秘钥" prop="privateKey">
          <el-input
            v-model="weChatAddForm.privateKey"
            placeholder="请输入秘钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="returnUrl">
          <el-input
            v-model="weChatAddForm.returnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="H5回调地址" prop="h5ReturnUrl">
          <el-input
            v-model="weChatAddForm.h5ReturnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 支付宝支付方式 -->
      <el-form
        :model="alipayAddForm"
        :rules="formRules"
        ref="alipayaddRef"
        label-width="150px"
        class="demo-ruleForm"
        v-show="alipay"
      >
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="alipayAddForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="原生h5" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APPID" prop="appId">
          <el-input
            v-model="alipayAddForm.appId"
            placeholder="请输入APPID"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公钥" prop="publicKey">
          <el-input
            v-model="alipayAddForm.publicKey"
            placeholder="请输入公钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="秘钥" prop="privateKey">
          <el-input
            v-model="alipayAddForm.privateKey"
            placeholder="请输入秘钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="returnUrl">
          <el-input
            v-model="alipayAddForm.returnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="H5回调地址" prop="h5ReturnUrl">
          <el-input
            v-model="alipayAddForm.h5ReturnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 杭州银行支付方式 -->
      <el-form
        :model="hzyhAddForm"
        :rules="formRules"
        ref="hzyhaddRef"
        label-width="150px"
        class="demo-ruleForm"
        v-show="hzyh"
      >
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="hzyhAddForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="聚合支付h5" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行商户号" prop="mchId">
          <el-input
            v-model="hzyhAddForm.mchId"
            placeholder="请再次输入银行商户号"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="returnUrl">
          <el-input
            v-model="hzyhAddForm.returnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="H5回调地址" prop="h5ReturnUrl">
          <el-input
            v-model="hzyhAddForm.h5ReturnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 工商银行 -->
      <el-form
        :model="gsyhAddForm"
        :rules="formRules"
        ref="gsyhaddRef"
        label-width="150px"
        class="demo-ruleForm"
        v-show="gsyh"
      >
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="gsyhAddForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="聚合支付h5" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APPID" prop="appId">
          <el-input
            v-model="gsyhAddForm.appId"
            placeholder="请输入APPID"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行商户号" prop="mchId">
          <el-input
            v-model="gsyhAddForm.mchId"
            placeholder="请再次输入银行商户号"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公钥" prop="publicKey">
          <el-input
            v-model="gsyhAddForm.publicKey"
            placeholder="请输入公钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="秘钥" prop="privateKey">
          <el-input
            v-model="gsyhAddForm.privateKey"
            placeholder="请输入秘钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="returnUrl">
          <el-input
            v-model="gsyhAddForm.returnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="H5回调地址" prop="h5ReturnUrl">
          <el-input
            v-model="gsyhAddForm.h5ReturnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑支付方式"
      :visible.sync="isShowEdit"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="支付方式名称" prop="type">
          <el-select
            v-model="editForm.type"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in paytype"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付分组" prop="nativeGroup">
          <el-select
            v-model="editForm.nativeGroup"
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="聚合支付h5" :value="1"></el-option>
            <el-option label="原生h5" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="APPID"
          prop="appId"
          v-show="
            editWeChat == '微信' ||
            editWeChat == '支付宝' ||
            editWeChat == '工商银行'
          "
        >
          <el-input
            v-model="editForm.appId"
            placeholder="请输入APPID"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="微信或银行商户号"
          prop="mchId"
          v-show="
            editWeChat == '微信' ||
            editWeChat == '建行' ||
            editWeChat == '杭州银行' ||
            editWeChat == '工商银行'
          "
        >
          <el-input
            v-model="editForm.mchId"
            placeholder="请再次输入微信或银行商户号"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="银行柜台代码"
          prop="posId"
          v-show="editWeChat == '建行'"
        >
          <el-input
            v-model="editForm.posId"
            placeholder="请输入银行柜台代码"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="银行分行代码"
          prop="bankId"
          v-show="editWeChat == '建行'"
        >
          <el-input
            v-model="editForm.bankId"
            placeholder="请输入银行分行代码"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公钥"
          prop="publicKey"
          v-show="
            editWeChat == '建行' ||
            editWeChat == '支付宝' ||
            editWeChat == '工商银行'
          "
        >
          <el-input
            v-model="editForm.publicKey"
            placeholder="请输入公钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="editWeChat == '建行' ? '银行api密码' : '公众号的Appsecret'"
          prop="quPwd"
          v-show="editWeChat == '建行' || editWeChat == '微信'"
        >
          <el-input
            v-model="editForm.quPwd"
            placeholder="请输入银行api密码"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="秘钥"
          prop="privateKey"
          v-show="
            editWeChat == '微信' ||
            editWeChat == '支付宝' ||
            editWeChat == '工商银行'
          "
        >
          <el-input
            v-model="editForm.privateKey"
            placeholder="请输入秘钥"
            style="width: 240px"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="回调地址"
          prop="returnUrl"
          v-show="
            editWeChat == '微信小程序' ||
            editWeChat == '杭州银行' ||
            editWeChat == '支付宝' ||
            editWeChat == '微信' ||
            editWeChat == '工商银行'
          "
        >
          <el-input
            v-model="editForm.returnUrl"
            placeholder="请输入回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="H5回调地址"
          prop="returnUrl"
          v-show="editWeChat == '杭州银行'"
        >
          <el-input
            v-model="editForm.h5ReturnUrl"
            placeholder="请输入H5回调地址"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findByPkIdList, pkPayChannel } from '@/api/Parkinglot.js'
import { sysDictData } from '@/api/dictionaries.js'
import { json } from 'body-parser'
export default {
  data() {
    return {
      isShowData: false,
      isShowAdd: false,
      isShowEdit: false,
      tableData: [],
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入登入', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
      },
      // 添加支付宝方式
      addForm: {
        type: undefined,
      },
      // 杭州银行
      hzyhAddForm: {
        // 名称
        type: undefined,
        pkId: parseInt(this.$route.query.id), //停车场id
        // 银行商户号
        mchId: undefined,
        nativeGroup: undefined,
        returnUrl: undefined,
        // h5回调地址
        h5ReturnUrl: undefined,
      },
      // 建行数据
      ccbAddForm: {
        // 名称
        type: undefined,
        pkId: parseInt(this.$route.query.id), //停车场id
        // 银行商户号
        mchId: undefined,
        // 银行柜台代码
        posId: undefined,
        // 银行分行代码
        bankId: undefined,
        // 公钥
        publicKey: undefined,
        // 查询密码
        quPwd: undefined,
        // 支付分组
        nativeGroup: undefined,
      },
      // 支付宝数据
      alipayAddForm: {
        // 名称
        type: undefined,
        pkId: parseInt(this.$route.query.id),
        // 支付宝id
        appId: undefined,
        // 支付宝公钥
        publicKey: undefined,
        // 支付宝私钥
        privateKey: undefined,
        // 回调地址
        returnUrl: undefined,
        // 支付分组
        nativeGroup: undefined,
      },
      // 微信数据
      weChatAddForm: {
        // 名称
        type: undefined,
        pkId: parseInt(this.$route.query.id),
        // 微信appid
        appId: undefined,
        // 微信商户号
        mchId: undefined,
        //公众号的appsecret
        quPwd: undefined,
        // 微信商户平台设置的密钥
        privateKey: undefined,
        //回调地址
        returnUrl: undefined,
        // 支付分组
        nativeGroup: undefined,
      },
      // 工商银行
      gsyhAddForm: {
        // 工商银行appid
        appId: undefined,
        // 工商银行商户号
        mchId: undefined,
        // 公钥
        publicKey: undefined,
        // 工商私钥
        privateKey: undefined,
        // 支付分组
        nativeGroup: undefined,
        //  回调地址
        returnUrl: undefined,
      },
      editForm: {
        // 编辑
        type: undefined,
        pkId: parseInt(this.$route.query.id),
        // 微信appid
        appId: undefined,
        // 微信商户号
        mchId: undefined,
        //公众号的appsecret
        quPwd: undefined,
        // 微信商户平台设置的密钥
        privateKey: undefined,
        //回调地址
        returnUrl: undefined,
        // 支付分组
        nativeGroup: undefined,

        id: undefined,
        pkId: parseInt(this.$route.query.id),
        type: undefined,
        appId: undefined,
        mchId: undefined,
        posId: undefined,
        bankId: undefined,
        publicKey: undefined,
        quPwd: undefined,
        privateKey: undefined,
        nativeGroup: undefined,
        returnUrl: undefined,
        h5ReturnUrl: undefined,
      },
      // 支付方式列表
      paytype: [],
      // 建行
      ccb: true,
      // 微信
      weChat: false,
      // 支付宝
      alipay: false,
      // 杭州银行
      hzyh: false,
      // 工商银行
      gsyh: false,
      pkName: '', //停车场名称
      addpkName: '',
    }
  },
  watch: {
    '$route.query.id': {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.sysDictData()
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    this.sysDictData()
  },
  computed: {
    editWeChat() {
      var value = this.editForm.type
      const ccbArr = ['1', '2', '3']
      const weChatArr = ['4', '5']
      const alipayArr = ['6', '7']
      const hzyhArr = ['10', '11']
      const gsyhArr = ['15', '16']
      // 编辑为建行
      if (ccbArr.includes(value)) {
        return '建行'
      }
      // 编辑为微信
      if (weChatArr.includes(value)) {
        return '微信'
        // 支付宝分组
      }
      // 编辑为支付宝
      if (alipayArr.includes(value)) {
        return '支付宝'
      }
      // 编辑为杭州银行
      if (hzyhArr.includes(value)) {
        return '杭州银行'
      }
      // 编辑为工商银行
      if (gsyhArr.includes(value)) {
        return '工商银行'
      }
    },
  },
  methods: {
    // 判断选择哪种支付方式
    judgmentPayment() {
      var value = this.addForm.type
      // 建行分组
      const ccbArr = ['1', '2', '3']
      const weChatArr = ['4', '5']
      const alipayArr = ['6', '7']
      const hzyhArr = ['10', '11']
      // 工商银行
      const gsyhArr = ['15', '16']
      if (ccbArr.includes(value)) {
        this.ccbAddForm.type = value
        this.ccb = true
        this.weChat = false
        this.alipay = false
        this.gsyh = false
        // 微信分组
      }
      if (weChatArr.includes(value)) {
        this.weChatAddForm.type = value
        this.ccb = false
        this.weChat = true
        this.alipay = false
        this.gsyh = false
        // 支付宝分组
      }
      if (alipayArr.includes(value)) {
        this.alipayAddForm.type = value
        this.ccb = false
        this.weChat = false
        this.alipay = true
        this.gsyh = false
      }
      // 杭州银行分组
      if (hzyhArr.includes(value)) {
        this.hzyhAddForm.type = value
        this.ccb = false
        this.weChat = false
        this.alipay = false
        this.hzyh = true
        this.gsyh = false
      }
      // 工商银行分组
      if (gsyhArr.includes(value)) {
        this.gsyhAddForm.type = value
        this.ccb = false
        this.weChat = false
        this.alipay = false
        this.hzyh = false
        this.gsyh = true
      }
    },
    findQuery(code, list) {
      const item = list.find((item) => item.code == code)
      return item ? item.name : ''
    },
    sysDictData() {
      this.pkName = this.$route.query.pkName
      this.addpkName =
        '添加支付方式'  + '\xa0 \xa0 \xa0' + this.$route.query.pkName
      // 字典
      sysDictData({ typeId: 89 }).then((res) => {
        // 支付方式
        this.paytype = res.data.data
        // 设置默认添加支付方式
        this.addForm.type = this.paytype[0].code
        this.findByPkIdList()
      })
    },
    findByPkIdList() {
      // 根据停车场id查询
      findByPkIdList({ pkId: this.$route.query.id }).then((res) => {
        this.tableData = res.data.data
        this.isShowData = true
        this.loading = false
      })
    },
    findByPkIdListcx() {
      // 查询
      this.findByPkIdList()
    },
    findByPkIdListcz() {
      this.query.pkId = parseInt(this.$route.query.id)
      this.findByPkIdList()
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.alipayaddRef.resetFields()
      this.$refs.weChataddRef.resetFields()
      this.$refs.ccbaddRef.resetFields()
      this.$refs.hzyhaddRef.resetFields()
      this.$refs.gsyhaddRef.resetFields()
    },
    addClick() {
      var value = this.addForm.type
      const ccbArr = ['1', '2', '3']
      const weChatArr = ['4', '5']
      const alipayArr = ['6', '7']
      const hzyhArr = ['10', '11']
      const gsyhArr = ['15', '16']
      // 添加
      // 杭州银行分组
      if (hzyhArr.includes(value)) {
        this.$refs.hzyhaddRef.validate((valid) => {
          if (!valid) return
          this.hzyhAddForm.type = this.addForm.type
          pkPayChannel(this.hzyhAddForm).then((res) => {
            if (res.data.code === '200') {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.findByPkIdList()
            }
          })
        })
      }
      // 建行分组
      if (ccbArr.includes(value)) {
        this.$refs.ccbaddRef.validate((valid) => {
          if (!valid) return
          this.ccbAddForm.type = this.addForm.type
          pkPayChannel(this.ccbAddForm).then((res) => {
            if (res.data.code === '200') {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.findByPkIdList()
            }
          })
        })
      }
      // 微信分组
      if (weChatArr.includes(value)) {
        this.$refs.weChataddRef.validate((valid) => {
          if (!valid) return
          this.weChatAddForm.type = this.addForm.type
          pkPayChannel(this.weChatAddForm).then((res) => {
            if (res.data.code === '200') {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.findByPkIdList()
            }
          })
        })
      }
      // 支付宝分组
      if (alipayArr.includes(value)) {
        this.$refs.alipayaddRef.validate((valid) => {
          if (!valid) return
          this.alipayAddForm.type = this.addForm.type
          pkPayChannel(this.alipayAddForm).then((res) => {
            if (res.data.code === '200') {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.findByPkIdList()
            }
          })
        })
      }
      // 工商银行分组
      if (gsyhArr.includes(value)) {
        this.$refs.gsyhaddRef.validate((valid) => {
          if (!valid) return
          this.gsyhAddForm.type = this.addForm.type
          pkPayChannel(this.gsyhAddForm).then((res) => {
            if (res.data.code === '200') {
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.findByPkIdList()
            }
          })
        })
      }
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      var add = JSON.parse(JSON.stringify(scope.row))
      this.editForm = add
      this.editForm.nativeGroup = parseInt(this.editForm.nativeGroup)
      this.isShowEdit = true
    },
    editClick() {
      // 编辑
      this.$refs.editRef.validate((valid) => {
        if (!valid) return
        pkPayChannel(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowEdit = false
            this.$message.success('编辑成功')
            this.findByPkIdList()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.name {
  margin-left: 2%;
}
</style>
